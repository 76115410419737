.tag-style{
    margin-bottom: 5px;
    width: 100%;
    border: 0.5px solid rgb(218, 216, 216);
    border-radius: 6px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    background-color: var(--neutral-2);
    height: 30px,
}
