.password-reset-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .password-reset-form-inputs {
    margin: 25px 0;
    width: 100%;
  }
}

.link-go-to-login {
  color: var(--absolute-blue-6);
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    color: var(--absolute-blue-5);
  }
}
