.projection-list-card {
  &[data-type='1'] {
    box-shadow: var(--card-shadow-sales);
  }
  &[data-type='2'] {
    box-shadow: var(--card-shadow-expenses);
  }
  &[data-type='3'] {
    box-shadow: var(--card-shadow-report-grouper);
  }
  &[data-type='4'] {
    box-shadow: var(--card-shadow-reports-self-service);
  }
  &[data-type='5'] {
    box-shadow: var(--card-shadow-other-05);
  }
  &[data-type='6'] {
    box-shadow: var(--card-shadow-extraordinary-expenses);
  }
  &[data-type='pnl'] {
    box-shadow: var(--card-shadow-report-totalizer);
  }

   &:hover{
      .drag-card-icon{
        visibility: visible;
      }
   }

   .drag-card-icon{
    visibility: hidden;
   }
}
