.control-detail-container {
  .control-table-actions {
    margin-bottom: 8px;
  }

  .style-detail-space {
    display: flex;
    flex-wrap: wrap;
  }

  .link-to-configuration {
    color: var(--absolute-blue-6);
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}
