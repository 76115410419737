.top-collapsable-menu {
  background-color: var(--neutral-2);
  box-shadow: var(--inner-shadow-1-border-down);
  position: sticky;
  top: 0;
  padding: 5px 0px;
  z-index: 1000;
  &.collapsed {
    box-shadow: none;
    background-color: transparent;
    position: fixed;
    top: 56px;
    padding-top: 0px;
  }
}
