.configuration-container {
  .info-empty-card {
    padding: 24px;
    align-self: start;
  }
  .configuration-card {
    max-height: 600px;
    box-shadow: var(--card-shadow-default);
    &.disabled {
      background-color: var(--neutral-4);
      .configuration-card-title {
        cursor: not-allowed;
      }
    }
    .ant-card-head-title {
      white-space: normal;
    }
    .configuration-card-title {
      cursor: pointer;
      &[data-hoverable='false'] {
        pointer-events: none;
      }
    }
    .ant-card-body {
      padding: 0;
      .configuration-card-body {
        width: 100%;
        > :first-child {
          cursor: pointer;
          padding: 24px;
          &[data-hoverable='false'] {
            pointer-events: none;
            min-height: 80px;
          }
        }
        .configuration-card-list {
          list-style: none;
          padding: 0px;
          overflow-y: auto;
          max-height: 400px;
          margin-bottom: 0px;
          li {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            box-shadow: var(--inner-shadow-1-border-up);
            &:hover {
              background-color: var(--neutral-4);
              cursor: pointer;
            }
            &.li-report {
              cursor: not-allowed;
            }
            &[data-hoverable='false'] {
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}
