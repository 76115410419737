.plika-login-container {
  background-image: url('../../../../assets/images/login/login-image.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 90%;
  min-height: 100vh;
  .brand-hero {
    margin-top: 40px;
    .brand-logo {
      margin-bottom: 50px;
    }
  }
  .login-form-container {
    background-color: var(--neutral-5);
    min-height: 100vh;
    .login-sector {
      // margin-top: 165px;
      margin-top: 100px;
      width: 100%;
      padding: 0px 123px;
      .forgot-password-container{
        display:flex;
        justify-content: center;
      }
    }
    .signin-sector {
      margin-top: 145px;
      width: 100%;
      padding: 0px 123px;
    }
  }
}
