.chat-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-sidebar-user-view {
  padding: 16px 24px 10px;
}

.chat-sidebar-search-view {
  padding: 0 24px 8px;
  width: 100%;
}

.chat-sidebar-search {
  position: relative;

  & .ant-input {
    padding: 8px 14px;
    height: 36px;
  }

  & .ant-input-search-button {
    height: 36px;
    width: 36px;
    box-shadow: none;

    & .anticon svg {
      display: block;
    }
  }

  & .ant-input-group > .ant-input:last-child,
  & .ant-input-group-addon:last-child,
  &.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {

    [dir=rtl] & {
      border-radius: 6px 0 0 6px;
    }
  }
}

.chat-list-item {
  display: flex;
  padding: 8px 8px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 71, 186, 0.3);
  }
}


.chat-list-item-content {
  font-size: 14px;
  padding-left: 14px;
  padding-right: 14px;
  width: 100%;
  color: rgb(107, 114, 128);
  overflow: hidden;

  [dir=rtl] & {
    padding-left: 0;
    padding-right: 16px;
  }

  & h3 {
    font-weight: 500;
    margin-bottom: 2px;
    font-size: 14px;
  }
}

.chat-tab-item {
  display: flex;
  flex-direction: column;
  align-items: center;

  & svg {
    font-size: 16px;
  }
}

.chat-sidebar-scrollbar {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-sidebar-title {
  padding: 8px 20px 4px;
  font-weight: 600;
  font-size: 16px;
}
