.core-typography-headline {
  margin: 0;
  color: var(--font-black-title);
  &[data-type='primary'] {
    color: var(--font-black-primary-text);
  }
  &[data-type='secondary'] {
    color: var(--font-black-secondary-text);
  }
  &[data-type='white'] {
    color: var(--white);
  }
  &[data-align='center'] {
    text-align: center;
  }
  &[data-type='disable'] {
    color: var(--font-black-disable);
  }
}

h1.core-typography-headline {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  font-size: 68px;
  line-height: 80px;
}

h2.core-typography-headline {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: 56px;
  line-height: 64px;
  letter-spacing: -0.005em;
}

h3.core-typography-headline {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-size: 38px;
  line-height: 48px;
  letter-spacing: 0.0025em;
}

h4.core-typography-headline {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: 30px;
  line-height: 36px;
}

h5.core-typography-headline {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-x4);
  line-height: 32px;
  letter-spacing: 0.0025em;
}

h6.core-typography-headline {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-x5);
  line-height: 31px;
  letter-spacing: 0.02em;
}

span.core-typography-subtitle {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-x2);
  line-height: 24px;
  letter-spacing: 0.0015em;
}

.core-typography-subtitle {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-x2);
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: var(--font-black-primary-text);
  &[data-level='2'] {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-sm);
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  &[data-level='3'] {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-x2);
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  &[data-type='secondary'] {
    color: var(--font-black-secondary-text);
  }
  &[data-type='disable'] {
    color: var(--font-black-disable);
  }
}

.core-typography-body {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-x2);
  line-height: 24px;
  letter-spacing: 0.005em;
  color: var(--font-black-title);
  &[data-level='2'] {
    font-size: var(--font-size-base);
    line-height: 22px;
    letter-spacing: 0.0025em;
  }
  &[data-level='3'] {
    font-size: var(--font-size-sm);
    line-height: 16px;
    letter-spacing: 0.0025em;
  }
  &[data-type='primary'] {
    color: var(--font-black-primary-text);
  }
  &[data-type='secondary'] {
    color: var(--font-black-secondary-text);
  }
  &[data-type='disable'] {
    color: var(--font-black-disable);
  }
  &[data-type='danger'] {
    color: var(--sizziling-red-6);
  }
  &[data-type='total'] {
    color: var(--absolute-blue-8);
  }
  &.kpi-extra-small {
    &:hover {
      cursor: default;
    }
  }
}

.core-typography-icon {
  font-size: var(--font-size-icon-sm);
  &[data-level='2'] {
    font-size: var(--font-size-icon-base);
  }
  &[data-level='3'] {
    font-size: var(--font-size-icon-x2);
  }
  &[data-type='primary'] {
    color: var(--font-black-primary-text);
  }
  &[data-type='secondary'] {
    color: var(--font-black-secondary-text);
  }
  &[data-type='disable'] {
    color: var(--font-black-disable);
  }
  &[data-type='danger'] {
    color: var(--sizziling-red-6);
  }
  &[data-type='warning'] {
    color: var(--cyber-yellow-6);
  }
}
