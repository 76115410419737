.ant-carousel > .dashboard-kpi-carousel {
  .slick-arrow.report-kpi-carousel-arrow {
    color: var(--font-black-primary-text);
    background-color: transparent;
    border-color: transparent;
    border-radius: 50%;
    font-size: var(--font-size-x4);
    &:hover,
    :focus {
      color: var(--absolute-blue-5);
    }
  }
  .slick-list {
    padding: 5px;
    .slick-track {
      .slick-slide {
        .dashboard-detail-kpi-card {
          margin-right: 24px;
          &[data-type='1'] {
            box-shadow: var(--card-shadow-sales);
          }
          &[data-type='2'] {
            box-shadow: var(--card-shadow-expenses);
          }
          &[data-type='3'] {
            box-shadow: var(--card-shadow-report-grouper);
          }
          &[data-type='4'] {
            box-shadow: var(--card-shadow-reports-self-service);
          }
          &[data-type='5'] {
            box-shadow: var(--card-shadow-other-05);
          }
          &[data-type='6'] {
            box-shadow: var(--card-shadow-extraordinary-expenses);
          }
        }
      }
    }
  }
}