.dashboard-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.list-card-novedades {
  &[data-type='novedades'] {
    box-shadow: var(--card-shadow-default);
  }

    .card-body{
      display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        .container-description {
            grid-area: 1 / 1 / 6 / 6;
            display: none;
            transition: all 0.3s ease;
        }

        .container-imagen {
            grid-area: 1 / 1 / 6 / 6;
            transition: all 0.2s ease;
        }
    }
    
    &:hover {
      justify-content: flex-start;
      cursor: pointer;

      .card-body {
          .container-description {
              display: flex;
              justify-content: flex-start;
              flex-direction: column;
              padding: 24px;
          }

          .container-imagen {
              opacity: 0;
          }
      }
  }
}
