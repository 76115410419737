.collapse-exchange-rate-header {
  background: var(--neutral-2);
  padding: 12px 16px 12px 12px;
  display: flex;
  justify-content: space-between;
  border-right: 1px solid var(--neutral-5);
  border-top: 1px solid var(--neutral-5);
  border-left: 1px solid var(--neutral-5);
  color: var(--neutral-12);
}
