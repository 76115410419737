.dashboard-container {
    font-family: sans-serif;
    text-align: center;
  }
  
  .not-permission-screen {
    width: 100%;
    text-align: center;
    margin-top: 24px;
  }
  