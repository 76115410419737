.upload-dragger-screen {
  width: 100%;
  .upload-dragger-download-alert {
    .upload-dragger-download-tempate {
      color: var(--absolute-blue-6);
      &:hover {
        cursor: pointer;
        color: var(--absolute-blue-5);
      }
    }
  }
  .upload-dragger-screen-containter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .upload-dragger-title {
      text-align: center;
      padding: 0 24px;
    }
  }
}
