.app-layout {
  &.ant-layout {
    min-height: 100vh;
  }
  .app-layout-header {
    position: fixed;
    z-index: 3;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--layout-header-background-light);
    box-shadow: var(--inner-shadow-1-border-down);
    .app-brand-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: calc(var(--layout-header-padding) * -1);
      height: var(--layout-header-height);
      width: var(--layout-header-height);
      box-shadow: var(--inner-shadow-1-border-right);
      &:hover {
        cursor: pointer;
      }
    }
    .app-brand-logo-complete {
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--layout-header-height);
      &:hover {
        cursor: pointer;
      }
    }
    .app-layout-actions {
      margin: 40px;
      margin-right: 105px;
      .app-layout-user-section {
        border-radius: 35px;
        border: none;
        font-size: var(--font-size-x2);
        &:hover {
          background-color: var(--absolute-blue-1);
        }
        &.selected {
          color: var(--absolute-blue-6);
        }
        &:hover {
          color: var(--absolute-blue-5);
        }
      }
      .header-icon {
        font-size: var(--font-size-x2);
        color: var(--font-black-primary-text);
        margin-left: 5px;
        margin-right: 12px;
        &.selected {
          color: var(--absolute-blue-6);
        }
        &:hover {
          color: var(--absolute-blue-5);
        }
      }
      // .header-icon {
      //   font-size: var(--font-size-x2);
      //   color: var(--font-black-primary-text);
      //   &.selected {
      //     color: var(--absolute-blue-6);
      //   }
      //   &:hover {
      //     color: var(--absolute-blue-5);
      //   }
      // }
    }
  }
  .app-layout-body {
    margin-top: 56px;
    overflow: auto;
  }
}
