.initial-screen-container{
    padding: var(--sublayout-padding);
    .initial-screen-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
  .initial-screen-steps-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    .initial-screen-steps-space{
      width: 15%;
      margin-left: 24px;
      margin-top: 30px;
      .initial-screen-steps-img{
        z-index: 2;
        position: absolute;
        top: -5px;
      }
      .initial-screen-steps-text{
        z-index: 1;
        position: relative;
        padding: 60px 5px;
        height: 200px;
        border-radius: 6px;
        background: linear-gradient(
          to right,
          #ffffff,
          #ffffff,
          #fafafa,
          #f5f5f5,
          #f0f0f0,
          #d9d9d9,
          #bfbfbf
        );
      }
    }

  }
}
