
.upload-moda-help {
    span {
        color: var(--absolute-blue-6);
        &:hover {
        cursor: pointer;
        color: var(--absolute-blue-5);
        }
    }
}
