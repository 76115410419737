.tracing-list-card{
  &[data-type='1'] {
      box-shadow: var(--card-shadow-sales);
    }
    &[data-type='2'] {
      box-shadow: var(--card-shadow-expenses);
    }
    &[data-type='3'] {
      box-shadow: var(--card-shadow-report-grouper);
    }
    &[data-type='4'] {
      box-shadow: var(--card-shadow-reports-self-service);
    }
    &[data-type='5'] {
      box-shadow: var(--card-shadow-other-05);
    }
    &[data-type='6'] {
      box-shadow: var(--card-shadow-extraordinary-expenses);
    }
    &[data-type='globalBudget'] {
      box-shadow: var(--card-shadow-default);
    }
    &[data-type='globalForecast'] {
      box-shadow: var(--card-shadow-default);
    }
    &[data-type='pnl'] {
      box-shadow: var(--card-shadow-report-totalizer);
    }

    &:hover{
      .drag-card-icon{
        visibility: visible;
      }
    }

     .drag-card-icon{
        visibility: hidden;
      }

    .number-container-col{
      display:flex;
      justify-content:center;
      align-items:center;
      border-right-color:#d9d9d9;
      border-right-style:solid;
      border-right-width:1px;

      .title-style{
          &:hover{
              cursor: pointer;
          }
      }

      .text-container{
          display:flex;
          justify-content:center;
      }
  }
  .tooltip-chart-container{
    display: flex;
    align-items: center;
    position: absolute;
    right:5px;
    padding:5px;
    border-radius: 6px;
    background-color:#ffffff;
    border: 1px solid #d9d9d9;
    min-width:300px;
  }
  .chart-cotainer{
      // width:100%;
      border-right-color:#d9d9d9;
      border-right-style:solid;
      border-right-width:1px;
  }
}
