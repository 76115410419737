.s2-header-heading{
    background-color: var(--neutral-2);
}
.s2-header-content{
    background-color: var(--neutral-2);

}

.palette-legend {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
  }

  .palette-color {
    width: 12px;
    height: 12px;
  }

  .palette-limit{
    font-size: 12px;
    color: rgb(94,94,94);
  }

  .palette-color + .palette-limit {
    margin-left: 5px;
  }

  .palette-limit + .palette-color {
    margin-left: 5px;
  }