.adjustment-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .adjustment-table-title{
    background: var(--neutral-4);
    padding: 16px;
  }
}
