.bc-button-action-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  &.start {
    justify-content: flex-start;
  }
  &.end {
    justify-content: flex-end;
  }
  &.center {
    justify-content: center;
  }
  &.space-between {
    justify-content: space-between;
  }
  > :not(:last-child) {
    margin-right: 10px;
  }
}
