.table-filter-dropdown {
  width: 240px;
  .table-filter-dropdown-searchbox {
    padding: 8px;
  }
  .table-filter-dropdown-list {
    max-height: 264px;
    overflow-x: hidden;
    border: 0;
    box-shadow: none;
    list-style-type: none;
    margin: 0;
    padding: 4px 0;
    .table-filter-dropdown-list-item {
      margin: 0;
      padding: 5px 12px;
      .table-filter-dropdown-option {
        width: 100%;
      }
    }
  }
  .table-filter-dropdown-buttons {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    overflow: hidden;
    background-color: inherit;
    border-top: 1px solid #f0f0f0;
  }
}
