.currency-card{
    width:100%;
    border-radius: 6px;

    .currency-card-body-cotainer{
        display:flex;
        justify-content: 'space-between';

        .currency-card-body-row{
            width:100%;
            padding-left:3%;

            .currency-card-body-symbol{
                display:flex;
                justify-content:center;
                align-items:center;
                border-radius: 50%;
                width: 40px;
                height: 40px;
            }

            .currency-card-body-col{
                display:flex;
                align-items:center;

                .currency-card-body-description{
                    width:300px;
                }
            }
        }

        .currency-card-body-extra-actions{
            padding-right:3%;
        }
    }
}
