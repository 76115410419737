.report-dashboard-chart-area-container{
    width: 100%;
    height: 100%;

    // .report-dashboard-chart-area {
    //     &[data-flex='true'] {
    //       width: 100%;
    //     //   height: 200px;
    //       padding: 24px;  
    //       border: 2px solid var(--neutral-4);
    //       border-style: dotted;
    //       display: flex;
    //       flex-direction: column;
    //       justify-content: center;
    //       align-items: center;
    //       user-select: none;
    //     }
    // }

    .report-dashboard-create{
        display: flex;
        align-items: center;
    }
    
}

.report-dashboard-chart-area-icon {
  background-color: #ffffff;
  // width: 20px;
  // padding-right: 5px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  margin-right: 5px;
  z-index: 1;
  top: 2px;
}
