.projection-tracking-screen {
  .projection-tracking-screen-space {
    width: 100%;
  }
  .details-tracking-screen-error {
    color: var(--sizziling-red-6);
  }
  .details-tracking-screen-complete {
    color: var(--go-green-6);
  }
}
