.core-empty-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .core-empty-screen-img {
    height: 260px;
  }
  .core-empty-screen-description {
    max-width: 500px;
    text-align: center;
  }
}
