.card-empty-comment {
&:hover{
    .drag-card-icon{
      visibility: visible;
    }
  }

   .drag-card-icon{
      visibility: hidden;
    }
}