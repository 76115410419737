input {
  &.cell-input-number {
    padding: 0px;
    border: none;
    text-align: end;
    font-size: var(--font-size-sm);
    background-color: transparent;
    &.red-cell {
      color: var(--sizziling-red-6);
    }
    &.font-width-financial {
      font-weight: 600;
      color: var(--absolute-blue-5);
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.cell-input-number {
  &.ant-input-affix-wrapper {
    padding: 0px;
    border: none;
    text-align: end;
    input:hover {
      cursor: pointer;
    }
  }
}
