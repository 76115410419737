
.container-top{
    padding: 10px 10px 0px 10px;
    width:100%;
    background-color:#f0f0f0;

    .container-elements{
        display: flex;
        justify-content: space-between;

        .img{
            position: absolute;
            z-index: 2;
            right: 15px;
            top: 90px;
            width: 240px;
        }
        .container-global-img{
            margin: 10px 15px;
            background-color: white;
            border-radius: 50%;
            width: 200px;
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            .photo{
                border-radius: 50%;
                width: 180px;
                height: 180px;
                object-fit: cover;
            }
            .container-img{
                background-color: #0047ba;
                border-radius: 50%;
                width: 180px;
                height: 180px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 80px;
            }
        }
    }

}