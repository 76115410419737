.dimensions-list-container {
  .dimension-search {
    width: 200px;
  }
  .dimensions-download-tempate {
    color: var(--absolute-blue-6);
    &:hover {
      cursor: pointer;
      color: var(--absolute-blue-5);
    }
  }
}
