.sider-layout {
  height: 80vh;
  position: 'fixed';
  left: 0;
  .sider-layout-content {
    overflow: auto;
    &.self-service-content {
      padding: 24px 24px;
    }
    padding: var(--sublayout-padding);
  }

  .padding{
    padding:15px;
  }

  .sider-layout-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: var(--inner-shadow-1-border-right);
    .sub-layout-actions {
      padding-top: 20px;
      .menu-icon {
        font-size: var(--font-size-x4);
        color: var(--font-black-secondary-text);
        &.selected {
          color: var(--absolute-blue-6);
        }
        &:hover {
          color: var(--absolute-blue-5);
        }
      }
    }
  }
  .float-btn {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    z-index: 99;
    display: block;
    border: none;
    position: fixed;
    width: 40px;
    height: auto;
    box-shadow: none;
    min-height: 40px;
    inset-inline-end: 24px;
    inset-block-end: 48px;
    border-radius: 6px;
  }
}
