.apps-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.apps-wrap-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  & h2 {
    color: rgb(17, 24, 39);
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
  }
}

.menu-btn {
  display: block;
  margin-right: 8px;
  padding: 0;
  background-color: transparent;
  border: 0 none;
  width: 35px;
  height: 35px;

  [dir=rtl] & {
    margin-right: 0;
    margin-left: 8px;
  }

  &:hover, &:focus {
    background-color: transparent;
    color: rgb(17, 24, 39);
  }

  @media screen and (min-width: 576px) {
    display: none;
  }

  & .anticon {
    font-size: 20px;
  }
}

.apps-container {
  display: flex;
  height: calc(100vh - 138px);

  .appMainFixedFooter & {
    height: calc(100vh - 184px) !important;
  }

  @media screen and (min-width: 576px) {
    height: calc(100vh - 161px);

    .appMainFixedFooter & {
      height: calc(100vh - 207px);
    }
  }

  @media screen and (min-width: 576px) {
    height: calc(100vh - 165px);

    .appMainFixedFooter & {
      height: calc(100vh - 211px);
    }
  }

  @media screen and (min-width: 576px) {
    height: calc(100vh - 153px);

    .appMainFixedFooter &,
    .appMainFooter & {
      height: calc(100vh - 199px);
    }
  }
}

.apps-main-content {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 576px) {
    width: calc(100% - 17.5rem);
    padding-left: 32px;

    [dir=rtl] & {
      padding-left: 0;
      padding-right: 32px;
    }
  }

  &.appsMainContentFull {
    @media screen and (min-width: 576px) {
      width: 100%;
      padding-left: 0;

      [dir=rtl] & {
        padding-right: 0;
      }
    }
  }
}

.apps-content-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px) !important;

  @media screen and (min-width: 576px) {
    height: calc(100% - 64px) !important;
  }

  &.fullView {
    height: 100% !important;
  }

  & .scrum-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    [dir=rtl] & {
      left: auto;
      right: 0;
    }
  }

  & .react-trello-board {
    height: 100%;
    background-color: var(--background-color-base) !important;

    & > div {
      height: 100%;
    }
  }

  & .scrum-row,
  & .react-trello-board > div > .smooth-dnd-container.horizontal {
    display: inline-flex;
    min-width: 100%;
    height: 100%;
    margin-left: -10px;
    margin-right: -10px;
  }

  & .scrum-col,
  & .react-trello-board .smooth-dnd-container .react-trello-lane {
    min-width: 280px;
    max-width: 280px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 16px;
    background-color: #fff;
    height: 100% !important;
    min-height: 100%;

    @media screen and (min-width: 576px) {
      min-width: 354px;
      max-width: 354px;
    }
  }

  & .react-trello-board .smooth-dnd-container .react-trello-lane > div:not(.scrum-board-list-header-card) {
    align-self: normal;
    overflow: hidden;
    max-height: none;
  }

  & .scroll-scrum-item {
    height: auto !important;
  }

  & .react-trello-board .smooth-dnd-container.vertical {
    overflow-y: auto;
    height: calc(100% - 72px);
  }
}

.apps-main-content-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  & > .ant-card-body {
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.apps-footer {
  padding: 8px 24px;
  border-top: 4px;
}

.apps-header {
  padding: 4px 24px;
  min-height: 56px;
  display: flex;
  align-items: center;
  border-bottom: 4px;

  @media screen and (min-width: 576px) {
    min-height: 64px;
  }
}

.apps-sidebar {
  height: 100%;
  display: none;
  flex-direction: column;

  @media screen and (min-width: 576px) {
    width: 30.5rem;
    display: flex;
  }
}

.apps-sidebar-drawer {

  & .ant-drawer-content-wrapper {
    width: 17.5rem !important;
  }

  & .ant-drawer-body {
    padding: 0;
  }
}

.apps-sidebar-card {
  display: none;
  height: 100%;
  @media screen and (min-width: 576px) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  & .ant-card-body {
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
