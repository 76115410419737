.pvtUi {
    color: rgba(0, 0, 0, 0.65);
    font-family: 'DM Sans', sans-serif;
    border-collapse: collapse;
    width: 100% !important;
}

.pvtUi select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.pvtUi td.pvtOutput {
    vertical-align: top;
}

.pvt-table-container {
    min-width: 100% !important;
    overflow: auto !important;
    height: 500px !important;
}

table.pvtTable {
    font-size: 8pt;
    text-align: left;
    border-collapse: collapse;
    margin-top: 3px;
    margin-left: 0 !important;
    font-family: 'DM Sans', sans-serif;
    width: 100% !important;
}

table.pvtTable thead {
    position: sticky;
    top: 0; /* Keep the header fixed at the top */
    z-index: 2; /* Ensure the header stays above the cells */
    background-color: #fafafa !important; /* Fixed background color */
    border: 1px solid #d9d9d9 !important; /* Fixed border color */
}

table.pvtTable thead th {
    background-color: #fafafa !important; /* Fixed background color */
    border: 1px solid #d9d9d9 !important; /* Fixed border color */
    font-size: 8pt;
    padding: 5px;
    z-index: 2; /* Ensure the header stays above the cells */
}

table.pvtTable tbody tr th {
    position: sticky;
    left: 0; /* Keep the first column fixed on the left */
    z-index: 1; /* Ensure the column cells stay below the header */
    background-color: #fafafa !important; /* Match the header's background color */
    border: 1px solid #d9d9d9 !important; /* Fixed border color */
    font-size: 8pt;
    padding: 5px;
}

table.pvtTable thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 3; /* Ensure the first header cell stays above the column cells */
    background-color: #fafafa !important; /* Fixed background color */
    border: 1px solid #d9d9d9 !important; /* Fixed border color */
}

table.pvtTable .pvtColLabel {
    text-align: center;
}

table.pvtTable .pvtTotalLabel {
    text-align: right;
    font-weight: bold;
    color: #101010;
}

table.pvtTable .pvtAxisLabel {
    font-weight: bold;
    color: #101010;
}

table.pvtTable .pvtColLabel {
    font-weight: bold;
    color: #101010;
    min-width: 80px;
}

table.pvtTable tbody tr td {
    color: rgba(0, 0, 0, 0.65);
    padding: 5px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    vertical-align: top;
    text-align: right;
}


.pvtTotal,
.pvtGrandTotal {
    font-weight: bold;
}

.pvtRowOrder,
.pvtColOrder {
    cursor: pointer;
    width: 17px;
    margin-left: 5px;
    display: inline-block;
    user-select: none;
    text-decoration: none !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    color: rgba(0, 0, 0, 0.65)
}

.pvtAxisContainer,
.pvtVals {
    border: 1px solid #d9d9d9;
    background: #fafafa;
    padding: 5px;
    min-width: 20px;
    min-height: 20px;
}

.pvtRenderers {
    padding-left: 5px;
    user-select: none;
}

.pvtDropdown {
    display: inline-block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    margin: 3px;
}

.pvtDropdownIcon {
    float: right;
    color: rgba(0, 0, 0, 0.65);
}

.pvtDropdownCurrent {
    text-align: left;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    display: inline-block;
    position: relative;
    width: 210px;
    box-sizing: border-box;
    background: white;
    color: rgba(0, 0, 0, 0.65);
}

.pvtDropdownCurrentOpen {
    border-radius: 6px 6px 0 0;
}

.pvtDropdownMenu {
    background: white;
    position: absolute;
    width: 100%;
    margin-top: -1px;
    border-radius: 0 0 6px 6px;
    border: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    box-sizing: border-box;
}

.pvtDropdownValue {
    padding: 2px 8px;
    font-size: 14px;
    text-align: left;
}

.pvtDropdownActiveValue {
    background: #ebf0f8;
}

.pvtVals {
    text-align: center;
    white-space: nowrap;
    vertical-align: top;
    padding-bottom: 12px;
}

.pvtRows {
    height: 35px;
}

.pvtAxisContainer li {
    padding: 8px 6px;
    list-style-type: none;
    cursor: move;
}

.pvtAxisContainer li.pvtPlaceholder {
    -webkit-border-radius: 6px;
    padding: 3px 15px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    border: 1px dashed #d9d9d9;
}

.pvtAxisContainer li.pvtPlaceholder span.pvtAttr {
    display: none;
}

.pvtAxisContainer li span.pvtAttr {
    -webkit-text-size-adjust: 100%;
    background: #fff;
    border: 1px solid #d9d9d9;
    padding: 3px 8px;
    white-space: nowrap;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.pvtTriangle {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65);
}

.pvtHorizList li {
    display: inline-block;
}

.pvtVertList {
    vertical-align: top;
}

.pvtFilteredAttribute {
    background: #d9d9d9 !important;
}

.sortable-chosen .pvtFilterBox {
    display: none !important;
}

.pvtCloseX {
    position: absolute;
    right: 5px;
    /*top: 5px;*/
    font-size: 20px;
    cursor: pointer;
    text-decoration: none !important;
    color: rgba(0, 0, 0, 0.65);
}

.pvtDragHandle {
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 18px;
    cursor: move;
    color: rgba(0, 0, 0, 0.65);
}

.pvtButton {
    color: rgba(0, 0, 0, 0.65);
    border-radius: 6px;
    padding: 3px 6px;
    background: #fff;
    border: 1px solid;
    border-color: #d9d9d9;
    font-size: 14px;
    margin: 3px;
    transition: 0.34s all cubic-bezier(0.19, 1, 0.22, 1);
    text-decoration: none !important;
}

.pvtButton:hover {
    background: #d9d9d9;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
}

.pvtButton:active {
    background: #d9d9d9;
}

.pvtFilterBox input {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.65);
    padding: 0 3px;
    font-size: 14px;
}

.pvtFilterBox input:focus {
    border-color: #d9d9d9;
    outline: none;
}

.pvtFilterBox {
    z-index: 100;
    width: 300px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    position: absolute;
    text-align: center;
    user-select: none;
    min-height: 100px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.pvtFilterBox h4 {
    margin: 15px;
}

.pvtFilterBox p {
    margin: 10px auto;
}

.pvtFilterBox button {
    color: rgba(0, 0, 0, 0.65);
}

.pvtFilterBox input[type="text"] {
    width: 230px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 5px;
}

.pvtCheckContainer {
    text-align: left;
    font-size: 14px;
    white-space: nowrap;
    overflow-y: scroll;
    width: 100%;
    max-height: 30vh;
    border-top: 1px solid #d9d9d9;
}

.pvtCheckContainer p {
    margin: 0;
    margin-bottom: 1px;
    padding: 3px;
    cursor: default;
}

.pvtCheckContainer p.selected {
    background: #d9d9d9;
}

.pvtOnly {
    display: none;
    width: 35px;
    float: left;
    font-size: 12px;
    padding-left: 5px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65);
}

.pvtOnlySpacer {
    display: block;
    width: 35px;
    float: left;
}

.pvtCheckContainer p:hover .pvtOnly {
    display: block;
    color: rgba(0, 0, 0, 0.65);
}

.pvtCheckContainer p:hover .pvtOnlySpacer {
    display: none;
    color: rgba(0, 0, 0, 0.65);
}

.pvtRendererArea {
    padding: 5px;
}

.pvtTotalizer {
    background-color: #eaeaea !important;
}

.pvtRatio {
    color: #a73ba1 !important;
    font-weight: 600;
}

.pvtReferenceBase {
    background: #b1c0d8 !important;
}

.pvtFinancial {
    background: #b1c0d8 !important;
}

.border-left-none {
    border-left: none !important;
}

.border-right-none {
    border-right: none !important;
}

.pr-0 {
    padding-right: 5px !important;
}

.width-25 {
    width: 25px !important;
}

.width-min-250 {
    width: 300px !important;
    min-width: 300px !important;
}

.pvtRendererDropdown {
    width: 75%;
}

.pl-0 {
    padding-left: 5px !important;
}

.pl-1 {
    padding-left: 20px !important;
}

.pl-2 {
    padding-left: 30px !important;
}

.pl-3 {
    padding-left: 40px !important;
}

.pl-4 {
    padding-left: 50px !important;
}

.pl-5 {
    padding-left: 60px !important;
}

.pl-6 {
    padding-left: 70px !important;
}

.pl-7 {
    padding-left: 80px !important;
}

.pl-8 {
    padding-left: 90px !important;
}

.pl-9 {
    padding-left: 100px !important;
}

.pl-10 {
    padding-left: 110px !important;
}

.pl-11 {
    padding-left: 120px !important;
}

.pl-12 {
    padding-left: 130px !important;
}

.pl-13 {
    padding-left: 140px !important;
}

.pl-14 {
    padding-left: 150px !important;
}

.pl-15 {
    padding-left: 160px !important;
}

.pl-16 {
    padding-left: 170px !important;
}

.pl-17 {
    padding-left: 180px !important;
}

.pl-18 {
    padding-left: 190px !important;
}

.pl-19 {
    padding-left: 200px !important;
}

.pl-20 {
    padding-left: 210px !important;
}

.pl-21 {
    padding-left: 220px !important;
}

.py-1 {
    padding: 10px !important;
    color: rgba(0, 0, 0, 0.65);
}


.header_0 {
    position: -webkit-sticky;
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 0 !important;
}

.header_1 {
    position: -webkit-sticky; /* for Safari */
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 80px !important;
}

.header_2 {
    position: -webkit-sticky; /* for Safari */
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 160px !important;
}

.header_3 {
    position: -webkit-sticky; /* for Safari */
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 240px !important;
}

.header_4 {
    position: -webkit-sticky; /* for Safari */
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 320px !important;
}

.header_5 {
    position: -webkit-sticky; /* for Safari */
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 400px !important;
}

.header_6 {
    position: -webkit-sticky;
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 480px !important;
}

.header_7 {
    position: -webkit-sticky;
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 560px !important;
}

.header_8 {
    position: -webkit-sticky;
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 640px !important;
}

.header_9 {
    position: -webkit-sticky;
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 720px !important;
}

.header_10 {
    position: -webkit-sticky;
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 800px !important;
}

.header_11 {
    position: -webkit-sticky;
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 880px !important;
}

.header_12 {
    position: -webkit-sticky;
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 960px !important;
}

.header_13 {
    position: -webkit-sticky;
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 1040px !important;
}

.header_14 {
    position: -webkit-sticky;
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 1120px !important;
}

.header_15 {
    position: -webkit-sticky;
    position: sticky !important;
    min-width: 80px !important;
    max-width: 80px !important;
    left: 1200px !important;
}

.header_tot {
    min-width: 40px !important;
    max-width: 80px !important;
}

