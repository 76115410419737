.container-empty{
    display: flex;
    justify-content: center;
    padding: 10px;
    border: 1px dashed grey;

    &:hover{
        cursor: pointer;
    }
}
.header-table-combination{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    background-color: var(--neutral-4);
}
.header-table{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    background-color: var(--neutral-6);
}