.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .login-form-inputs {
    margin: 25px 0px 0px 0px;
    width: 100%;
  }
}
